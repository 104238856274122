import Config from "../Config";
import { authHeaderLicman, handlePossibleUnauthorizedResponse, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getMedia(guidFileName = null) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify({ guidFileName: guidFileName }),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMedia`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}
export function getMediaByMediaFoldersId(mediaFolderId) {
    const mf = {
        mediaFolderId: mediaFolderId,
    };

    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify(mf),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMediaByFoldersId`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}

export function createMedia(formData) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: formData,
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/createMedia`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}

export function updateMedia(media) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify(media),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/updateMedia`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}

export function deleteMedia(mediaId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify({ mediaId: mediaId }),
    };
    let requestURL = "/deleteMedia";
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}` + requestURL, requestOptions)
        .then((response) => handlePossibleUnauthorizedResponse(response));
    };
}

export function getMediaContains(searchString) {
    let encodedSearchString = encodeURIComponent(searchString.toLowerCase());
    const requestOptions = {
        method: "GET",
        headers: authHeaderLicman(),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMediaByString/${encodedSearchString}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getAltAndTitleByGuidFileName(guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify(guidFileName),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getAltAndTitleByGuidFileName`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getMediaByTag(tag) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderLicman(),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMediaByTag/${tag}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getAllowedTags() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderLicman(),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getAllowedTags`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}
