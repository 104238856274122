import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    Divider
} from "@mui/material";
import DailyClosing from "../../dailyClosing/dailyClosing";
import store from "../../../../store";
// Hier müssen wir noch die Action für das Laden der Tagesabschlüsse erstellen
import { getDailyClosings } from "../../../../actions/dailyClosingAction";

const DailyClosingOverview = () => {
    const { t } = useTranslation(["dynamicTranslation"]);
    const [dailyClosings, setDailyClosings] = useState([]);
    const [selectedDailyClosingId, setSelectedDailyClosingId] = useState(null);
    useEffect(() => {
        store.dispatch(getDailyClosings()).then((data) => {
            setDailyClosings(data);
        });
    }, []);

    const handleDailyClosingClick = (dailyClosingId) => {
        setSelectedDailyClosingId(dailyClosingId);
    };

    return (
        <Box sx={{ display: "flex", height: "calc(100vh - 64px)" }}>
            {/* Linke Seite - Liste der Tagesabschlüsse */}
            <Box sx={{
                width: "20%",
                p: 2,
                borderRight: 1,
                borderColor: "divider",
                overflowY: "auto"
            }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {t("dailyClosingOverview.title", "Tagesabschlüsse")}
                </Typography>
                <List>
                    {dailyClosings.map((closing, index) => (
                        <React.Fragment key={closing.id}>
                            <ListItem onClick={() => handleDailyClosingClick(closing.dailyClosingId)}>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        p: 2,
                                        width: "100%",
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            cursor: 'pointer'
                                        }
                                    }}
                                >
                                    <Typography variant="subtitle1">
                                        {new Date(closing.createdDatetime).toLocaleDateString('de-DE')}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {t("dailyClosingOverview.closingNumber", "Abschluss-Nr.")}: {closing.dailyClosingNumber}
                                    </Typography>
                                    <Typography variant="body1">
                                        {new Intl.NumberFormat('de-DE', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        }).format(closing.orderSum)}
                                    </Typography>
                                </Paper>
                            </ListItem>
                            {index < dailyClosings.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Box>

            {/* Rechte Seite - DoDailyClosing Komponente */}
            <Box sx={{ width: "100%", overflowY: "auto" }}>
                {selectedDailyClosingId && <DailyClosing dailyClosingId={selectedDailyClosingId} />}
            </Box>
        </Box>
    );
};

export default DailyClosingOverview;
