import Config from "../Config";
import { authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();


export function getSalesVolumeByOrderTypeForDailyClosing(dailyClosingId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getSalesVolumeByOrderTypeForDailyClosing/${dailyClosingId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function getSalesVolumeByTimeSlots(dailyClosingId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getSalesVolumeByTimeSlots/${dailyClosingId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function getSalesVolumeByTimeBlocks(dailyClosingId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getSalesVolumeByTimeBlocks/${dailyClosingId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function getSalesVolumeByPaymentTypeForDailyClosing(dailyClosingId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getSalesVolumeByPaymentTypeForDailyClosing/${dailyClosingId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function doDailyClosing(closingDate, comment) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ closingDate, comment })
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/doDailyClosing`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function getDailyClosings() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getDailyClosings`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}


export function getDailyClosing(dailyClosingId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getDailyClosing/${dailyClosingId ?? 0}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}