import { Drawer } from "devextreme-react";
import PropTypes from "prop-types";
import React, { useState, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./content.scss";
import Navigation from "./navigation/navigation";
import Page from "./page/page";

const Content = memo(({ noNav = false }) => {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const location = useLocation();
    const { user } = useSelector((state) => state.identity);

    const toggleNavigation = useCallback(() => {
        setNavigationOpen((prev) => !prev);
    }, []);

    const renderNavigation = useCallback(
        () => <Navigation toggleNavigation={toggleNavigation} open={navigationOpen} />,
        [toggleNavigation, navigationOpen]
    );

    // Function to check if the route should have a scrollbar
    const shouldDisableScrolling = (pathname) => {
        // Remove leading slash and hash
        const cleanPath = pathname.replace(/^[/#]+/, "");

        // List of routes without scrolling
        const noScrollRoutes = [
            "tagesabschluss",
            // Here you can add more routes
        ];

        return noScrollRoutes.includes(cleanPath);
    };

    return (
        <React.Fragment>
            {!noNav ? (
                <Drawer
                    opened={user.Username ? navigationOpen : false}
                    openedStateMode="shrink"
                    revealMode="expand"
                    position="left"
                    component={renderNavigation}
                    closeOnOutsideClick={true}
                    minSize={user.Username ? 55 : 0}
                    maxSize={350}
                >
                    <div id="main" className={`${location.pathname === "/" ? "home" : ""}`}>
                        <div
                            id="content"
                            className={`content ${
                                shouldDisableScrolling(location.pathname) ? "content-no-scrolling" : ""
                            }`}
                        >
                            <Page />
                        </div>
                    </div>
                </Drawer>
            ) : (
                <div id="main" className="guest">
                    <div
                        id="content"
                        className={`content ${shouldDisableScrolling(location.pathname) ? "content-no-scrolling" : ""}`}
                    >
                        <Page />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

Content.propTypes = {
    noNav: PropTypes.bool,
};

Content.displayName = "Content";
export default Content;
