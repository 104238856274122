import defaultSettingsJson from '../defaultSettings.json';
import { RECEIVE_LOGIN_TRANSLATIONS } from "../actions/menuCardAction";
import { RECEIVE_VERSION, RECEIVE_SETTINGS } from "../actions/settingActions";

// Helper function to parse setting values based on type
const parseSettingValue = (value, type) => {
    switch (type) {
        case 'time':
            return value;
        case 'integer':
            return parseInt(value);
        case 'double':
            return parseFloat(value);
        case 'boolean':
            return value.toLowerCase() === 'true';
        case 'array':
            return value ? value.split(',') : [];
        case 'date':
            return new Date(value);
        case 'color':
        case 'string':
        default:
            return value;
    }
};

// Helper function to create nested object structure
const createNestedObject = (key, value, target) => {
    const parts = key.split('.');
    let current = target;

    for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];
        current[part] = current[part] || {};
        current = current[part];
    }

    const lastPart = parts[parts.length - 1];
    current[lastPart] = value;
};

// Transform default settings into nested structure
const transformDefaultSettings = () => {
    const settings = {};

    Object.entries(defaultSettingsJson).forEach(([key, setting]) => {
        const settingObject = {
            type: setting.type,
            showOnSetupPage: setting.showOnSetupPage,
            validationRules: setting.validationRules,
            value: parseSettingValue(setting.value, setting.type)
        };

        createNestedObject(key, settingObject, settings);
    });

    return settings;
};

const initialState = {
    version: "",
    loginTranslations: {
        email: "E-Mail",
        password: "Passwort",
        fieldIsRequired: "Dieses Feld wird benötigt",
        enterLicenseKey: "Bitte gültigen Lizenzschlüssel eingeben!",
        login: "Login",
    },
    settings: transformDefaultSettings()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_VERSION:
            return Object.assign({}, state, {
                version: action.version.version,
            });
        case RECEIVE_LOGIN_TRANSLATIONS:
            return Object.assign({}, state, {
                loginTranslations: action.loginTranslations,
            });
        case RECEIVE_SETTINGS:
            return Object.assign({}, state, {
                settings: action.settings,
            });
        default:
            return state;
    }
};
