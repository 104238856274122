import Config from "../Config";
import { history } from "../helpers/history";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";
import { userService } from "../services/userService";
import { alertActions } from "./alertActions";
import { setUserFavorites } from "./identityActions";

const config = new Config();

export const userConstants = {
    REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
    REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
    REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

    UPDATEUSERROLE_SUCCESS: "UPDATEUSERROLE_SUCCESS",
    UPDATEUSERPASSWORD_SUCCESS: "UPDATEUSERPASSWORD_SUCCESS",
    UPDATEUSERDATA_SUCCESS: "UPDATEUSERDATA_SUCCESS",
    DELETEUSER_SUCCESS: "DELETEUSER_SUCCESS",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

    LOGOUT: "USERS_LOGOUT",

    GETALL_REQUEST: "USERS_GETALL_REQUEST",
    GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
    GETALL_FAILURE: "USERS_GETALL_FAILURE",

    GETALLROLES_REQUEST: "USERS_GETALLROLES_REQUEST",
    GETALLROLES_SUCCESS: "USERS_GETALLROLES_SUCCESS",
    GETALLROLES_FAILURE: "USERS_GETALLROLES_FAILURE",

    GETUSERROLES_REQUEST: "USERS_GETUSERROLES_REQUEST",
    GETUSERROLES_SUCCESS: "USERS_GETUSERROLES_SUCCESS",
    GETUSERROLES_FAILURE: "USERS_GETUSERROLES_FAILURE",

    GETUSERRIGHTS_REQUEST: "USERS_GETUSERRIGHTS_REQUEST",
    GETUSERRIGHTS_SUCCESS: "USERS_GETUSERRIGHTS_SUCCESS",
    GETUSERRIGHTS_FAILURE: "USERS_GETUSERRIGHTS_FAILURE",

    DELETE_REQUEST: "USERS_DELETE_REQUEST",
    DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
    DELETE_FAILURE: "USERS_DELETE_FAILURE",

    SET_NAVIGATIONFAVORITES_SUCCESS: "USERS_SETNAVIGATIONFAVORITES_SUCCESS",

    UPDATE_USER_LANGUAGE_SUCCESS: "UPDATE_USER_LANGUAGE_SUCCESS",

    GETUSERCASHONHAND_REQUEST: "USERS_GETUSERCASHONHAND_REQUEST",
    GETUSERCASHONHAND_SUCCESS: "USERS_GETUSERCASHONHAND_SUCCESS",
    GETUSERCASHONHAND_FAILURE: "USERS_GETUSERCASHONHAND_FAILURE",
};

export const userActions = {
    login,
    logout,
    getAll,
    register,
    getAllRoles,
    getUserRoles,
    getUserRights,
    updateUserRole,
    deleteUser,
};

function login(username, password) {
    return (dispatch) => {
        dispatch(request({ username }));

        userService.login(username, password).then(
            (user) => {
                dispatch(success(user));
                history.push("/");
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll(user) {
    return (dispatch) => {
        dispatch(request());

        return userService.getAll(user).then(
            (users) => dispatch(success(users)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

function getAllRoles() {
    return (dispatch) => {
        dispatch(request());

        return userService.getAllRoles().then(
            (roles) => dispatch(success(roles)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETALLROLES_REQUEST };
    }
    function success(roles) {
        return { type: userConstants.GETALLROLES_SUCCESS, roles };
    }
    function failure(error) {
        return { type: userConstants.GETALLROLES_FAILURE, error };
    }
}

function getUserRoles(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserRoles(user).then(
            (userroles) => dispatch(success(userroles)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERROLES_REQUEST };
    }
    function success(userroles) {
        return { type: userConstants.GETUSERROLES_SUCCESS, userroles };
    }
    function failure(error) {
        return { type: userConstants.GETUSERROLES_FAILURE, error };
    }
}

function getUserRights(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserRights(user).then(
            (userrights) => dispatch(success(userrights)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERRIGHTS_REQUEST };
    }
    function success(userrights) {
        return { type: userConstants.GETUSERRIGHTS_SUCCESS, userrights };
    }
    function failure(error) {
        return { type: userConstants.GETUSERRIGHTS_FAILURE, error };
    }
}

export function register(username, password, role) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            username: username,
            password: password,
            role: role,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/register", requestOptions).then((user) => {
            dispatch(success(user));
        });
    };

    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
}

export function updateNavigationFavorites(favorites) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            favorites,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateNavigationFavorites", requestOptions)
            .then((response) => handleResponse(response))
            .then((favorites) => {
                dispatch(setUserFavorites(favorites));
                return favorites;
            });
    };
}

export function getNavigationFavorites(previousFavorites) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/getNavigationFavorites", requestOptions)
            .then((response) => handleResponse(response))
            .then((favorites) => {
                if (JSON.stringify(favorites) !== JSON.stringify(previousFavorites))
                    dispatch(setUserFavorites(favorites));
                return favorites;
            });
    };
}

export function updateUserRole(userIdToChange, roleToChange) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            userIdToChange: userIdToChange,
            roleToChange: roleToChange,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateUserRole", requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERROLE_SUCCESS, user };
    }
}

export function updateUserData(userData) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify(userData),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/updateUserData`, requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERPASSWORD_SUCCESS, user };
    }
}

export function updateEmployeesCashOnHand(employeesId, cashOnHand, isDailyClosingAction) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ employeesId, cashOnHand, isDailyClosingAction }),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/updateEmployeesCashOnHand`, requestOptions)
            .then((response) => handleResponse(response));
    };
}

export function getUserCashOnHand(employeesId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };

    return function (dispatch) {
        dispatch(request());

        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getUsersCashOnHand/${employeesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((cashOnHand) => {
                dispatch(success(cashOnHand));
                return cashOnHand;
            })
            .catch((error) => {
                dispatch(failure(error));
                throw error;
            });
    };

    function request() {
        return { type: userConstants.GETUSERCASHONHAND_REQUEST };
    }
    function success(cashOnHand) {
        return { type: userConstants.GETUSERCASHONHAND_SUCCESS, cashOnHand };
    }
    function failure(error) {
        return { type: userConstants.GETUSERCASHONHAND_FAILURE, error };
    }
}

export function getEmployeesWithCash() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getEmployeesWithCash`, requestOptions)
            .then((response) => handleResponse(response));
    }
}

export function updateUserLanguage(language, longCode) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ languageCode: language, longLanguageCode: longCode }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateUserLanguage", requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.UPDATE_USER_LANGUAGE_SUCCESS, language };
    }
}

export function getUserLanguage() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/getUserLanguage", requestOptions)
            .then((response) => handleResponse(response))
            .then((language) => {
                dispatch(success(language));
                return language;
            });
    };

    function success(language) {
        return { type: userConstants.UPDATE_USER_LANGUAGE_SUCCESS, language };
    }
}

export function updateUserPassword(newPassword) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(newPassword),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateUserPassword", requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERPASSWORD_SUCCESS, user };
    }
}

export function deleteUser(usersId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(usersId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/deleteUser", requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.DELETEUSER_SUCCESS, user };
    }
}
