import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import store from "../../../store";
import { getDailyClosing, getSalesVolumeByOrderTypeForDailyClosing, getSalesVolumeByPaymentTypeForDailyClosing, getSalesVolumeByTimeSlots, getSalesVolumeByTimeBlocks } from "../../../actions/dailyClosingAction";
import PieChart, {
    Series,
    Label,
    Connector,
    Size,
    Export,
    PieChartTypes,
    Font,
  } from 'devextreme-react/pie-chart';
import { Chart, Series as ChartSeries, Tooltip, ValueAxis, Title } from 'devextreme-react/chart';

const DailyClosing = ({ dailyClosingId }) => {
    const { t } = useTranslation(["dynamicTranslation"]);
    const [salesVolumeByOrderType, setSalesVolumeByOrderType] = useState([]);
    const [salesVolumeByPaymentType, setSalesVolumeByPaymentType] = useState([]);
    const [dailyClosing, setDailyClosing] = useState({});
    const [salesVolumeByTimeSlots, setSalesVolumeByTimeSlots] = useState([]);
    const [salesVolumeByTimeBlocks, setSalesVolumeByTimeBlocks] = useState([]);
    useEffect(() => {
        store.dispatch(getSalesVolumeByOrderTypeForDailyClosing(dailyClosingId)).then((data) => {
            setSalesVolumeByOrderType(data.salesVolumeByOrderType);
        });
        store.dispatch(getSalesVolumeByPaymentTypeForDailyClosing(dailyClosingId)).then((data) => {
            setSalesVolumeByPaymentType(data.salesVolumeByPaymentType);
        });
        store.dispatch(getDailyClosing(dailyClosingId)).then((data) => {
            setDailyClosing(data);
        });
        store.dispatch(getSalesVolumeByTimeSlots(dailyClosingId)).then((data) => {
            setSalesVolumeByTimeSlots(data.salesByTimeSlot);
        });
        store.dispatch(getSalesVolumeByTimeBlocks(dailyClosingId)).then((data) => {
            setSalesVolumeByTimeBlocks(data.salesByTimeBlock);
        });
    }, [dailyClosingId]);

    const toggleVisibility = (item) => {
        if (item) {
            item.isVisible() ? item.hide() : item.show();
        }
    };

    const pointClickHandler = (e) => {
        toggleVisibility(e.target);
    };

    const legendClickHandler = (e) => {
        const arg = e.target;
        const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
        toggleVisibility(item);
    };

    const customizeText = (pointInfo) => {
        return `${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(pointInfo.value)}`;
    };

    const customizeTooltip = (pointInfo) => {
        return { text: `${pointInfo.argumentText}: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(pointInfo.value)}` };
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
                {t("doDailyClosing.title", "Tagesabschluss")}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-around", mb: 10 }}>
                <PieChart
                    id="pie"
                    dataSource={salesVolumeByOrderType}
                    palette="Bright"
                    title={t("doDailyClosing.bruttoSalesVolumeByOrderType", "Bruttoumsatz nach Ausgabeort")}
                    onPointClick={pointClickHandler}
                    onLegendClick={legendClickHandler}
                >
                    <Series argumentField="orderType" valueField="amount">
                        <Label
                            visible={true}
                            position="columns"
                            customizeText={customizeText}>
                            <Font size={14} />
                            <Connector visible={true} width={0.5} />
                        </Label>
                    </Series>
                    <Export enabled={true} />
                </PieChart>

                <PieChart
                    id="pie"
                    dataSource={salesVolumeByPaymentType}
                    palette="Bright"
                    title={t("doDailyClosing.bruttoSalesVolumeByPaymentType", "Bruttoumsatz nach Zahlungsart")}
                    onPointClick={pointClickHandler}
                    onLegendClick={legendClickHandler}
                >
                    <Series argumentField="paymentType" valueField="amount">
                        <Label
                            visible={true}
                            position="columns"
                            customizeText={customizeText}>
                            <Font size={14} />
                            <Connector visible={true} width={0.5} />
                        </Label>
                    </Series>
                    <Export enabled={true} />
                </PieChart>
                <Chart
                    dataSource={salesVolumeByTimeBlocks}
                    width={700}
                    palette="Bright"
                    title={t("doDailyClosing.bruttoSalesVolumeByTimeBlocks", "Umsätze im Tagesverlauf")}
                >
                    <ChartSeries type="bar" argumentField="timeBlock" valueField="grossAmount" name="Bruttoumsatz" />
                    <ValueAxis position="left">
                        <Title text="EUR" />
                    </ValueAxis>
                    <Tooltip
                        enabled={true}
                        location="edge"
                        customizeTooltip={customizeTooltip}
                    />
                </Chart>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around", mb: 3, width: "100%" }}>
                <Box sx={{ width: "50%" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-around", mb: 3 }}>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.orderSum", "Bruttoumsatz")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.orderSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.orderSum) : "-"}
                            </Typography>
                        </Paper>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.deliverLumpSum", "Liefergebühren")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.deliverLumpSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.deliverLumpSum) : "-"}
                            </Typography>
                        </Paper>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-around", mb: 3 }}>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.orderCreditSum", "Gutschriften")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.orderCreditSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.orderCreditSum) : "-"}
                            </Typography>
                        </Paper>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.minOrderChargeSum", "Mindermengenzuschläge")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.minOrderChargeSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.minOrderChargeSum) : "-"}
                            </Typography>
                        </Paper>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-around", mb: 3 }}>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.orderDiscountSum", "Rabatte")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.orderDiscountSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.orderDiscountSum) : "-"}
                            </Typography>
                        </Paper>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.paymentFeeSum", "Gebühren Zahlungsweisen")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.paymentFeeSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.paymentFeeSum) : "-"}
                            </Typography>
                        </Paper>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-around", mb: 3 }}>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.orderCancelSum", "Stornos")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.orderCancelSum ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dailyClosing.orderCancelSum) : "-"}
                            </Typography>
                        </Paper>
                        <Paper sx={{ p: 2, width: "40%" }}>
                            <Typography variant="h6">
                                {t("doDailyClosing.orderCreditCount", "Anzahl Gutschriften")}
                            </Typography>
                            <Typography variant="body1">
                                {dailyClosing.orderCreditCount}
                            </Typography>
                        </Paper>
                    </Box>
                </Box>
                <Box sx={{ width: "50%" }}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            {t("doDailyClosing.salesByTime", "Umsatz nach Zeit")}
                        </Typography>
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "left", padding: "8px" }}></th>
                                    <th style={{ textAlign: "right", padding: "8px" }}>{t("doDailyClosing.gross", "Brutto")}</th>
                                    <th style={{ textAlign: "right", padding: "8px" }}>{t("doDailyClosing.net", "Netto")}</th>
                                    <th style={{ textAlign: "right", padding: "8px" }}>{t("doDailyClosing.averageReceipt", "Ø Bon")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesVolumeByTimeSlots.map((slot) => (
                                    <tr key={slot.timeSlot}>
                                        <td style={{ padding: "8px" }}>{slot.timeSlot}</td>
                                        <td style={{ textAlign: "right", padding: "8px" }}>
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(slot.grossAmount)}
                                        </td>
                                        <td style={{ textAlign: "right", padding: "8px" }}>
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(slot.netAmount)}
                                        </td>
                                        <td style={{ textAlign: "right", padding: "8px" }}>
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(slot.averageReceiptAmount)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default DailyClosing;
