import notify from "devextreme/ui/notify";
import { history } from "./history";
import store from "../store";
import { setUser } from "../actions/identityActions";
import Config from "../Config";

const config = new Config();

export function authHeader() {
    // return authorization header with jwt token if logged in
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
        return {
            "Content-Type": "application/json",
            Authorization: user.Token,
        };
    }
    return { "Content-Type": "application/json" };
}

export function authHeaderLicman() {
    // return authorization header with jwt token if logged in
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
        return {
            Authorization: `Bearer ${user.Token}`,
            clientidentification: config.biosClientToken,
            licman: config.licmanKey,
        };
    }
    return {};
}

export function authHeaderForAzure() {
    // return authorization header with jwt token and clientIdentification
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.Token}`,
            clientidentification: config.biosClientToken,
        };
    }
    return { "Content-Type": "application/json" };
}

export function authHeaderForFGFunctions() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.Token}`,
            clientidentification: config.biosClientToken,
        };
    }
    return { "Content-Type": "application/json" };
}

export function translateResponse(response, t) {
    if (t) {
        response = response.map((e) => {
            if (e.name) e.name = t(e.name);
            if (e.toolName) e.toolName = t(e.toolName);
            if (e.description) e.description = t(e.description);
            if (e.property) e.property = t(e.property);
            if (e.additiveGroupsName) e.additiveGroupsName = t(e.additiveGroupsName);
            if (e.positiveFilterName) e.positiveFilterName = t(e.positiveFilterName);
            if (e.negativeFilterName) e.negativeFilterName = t(e.negativeFilterName);
            return e;
        });
    }
    return response;
}

export function handleResponse(response) {
    if (200 <= response.status && response.status < 400) {
        // if content type is pdf, return response
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/pdf")) {
            return response;
        }

        // else parse json as usual
        return response
            .clone()
            .text()
            .then(function (text) {
                try {
                    let responseParsed = JSON.parse(text);
                    return responseParsed;
                } catch {
                    if (text && text.trim().length > 0) {
                        return text;
                    } else {
                        return response;
                    }
                }
            });
    } else {
        if (response.status === 401) {
            store.dispatch(
                setUser({
                    Username: null,
                    Id: null,
                    Token: null,
                    Employee: null,
                })
            );
            history.push("/login");
            notify(getToast("Sitzung abgelaufen, bitte neu anmelden.", "error"));
            return response;
        } else if (response.status === 403) {
            notify(getToast("Fehlende Berechtigung!", "error"));
            return response;
        } else if (response.status === 404) {
        } else {
            var result = response.clone();
            response
                .json()
                .then((json) => notify(getToast("Es tut uns leid, es ist ein Fehler aufgetreten.", "error")));
            return result;
        }
    }
}

export function handlePossibleUnauthorizedResponse(response) {
    if (200 <= response.status && response.status < 400) {
        return response
            .clone()
            .text()
            .then(function (text) {
                try {
                    let responseParsed = JSON.parse(text);
                    return responseParsed;
                } catch {
                    if (text && text.trim().length > 0) {
                        return text;
                    } else {
                        return response;
                    }
                }
            });
    } else {
        if (response.status === 401) {
            notify(getToast("Hierzu fehlt Dir das Recht.", "error"));
            return response;
        } else {
            var result = response.clone();
            response
                .json()
                .then((json) => notify(getToast("Es tut uns leid, es ist ein Fehler aufgetreten.", "error")));
            return result;
        }
    }
}

export function getToast(msg, type) {
    var time = 1000;
    switch (type) {
        case "success":
            break;
        case "error":
            time = 5000;
            break;
        case "warning":
            time = 4000;
            break;
        case "info":
            time = 4000;
            break;
        default:
            break;
    }
    var toastSettings = {
        message: msg,
        type: type,
        displayTime: time,
        position: {
            my: "Top",
            at: "Top",
        },
        //width: function () { return $(window).width(); }
    };
    return toastSettings;
}
