import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faBars,
    faBook,
    faCapsules,
    faDatabase,
    faEgg,
    faFileImport,
    faFolderPlus,
    faHeart,
    faHome,
    faImage,
    faInfoCircle,
    faLayerGroup,
    faLink,
    faList,
    faListUl,
    faMinus,
    faMoneyBillAlt,
    faNetworkWired,
    faPaperclip,
    faPercent,
    faPlus,
    faPlusCircle,
    faPrescriptionBottle,
    faPrint,
    faRandom,
    faRedoAlt,
    faRuler,
    faSeedling,
    faSortAmountDown,
    faStar,
    faStream,
    faTag,
    faTrashAlt,
    faUndo,
    faUtensilSpoon,
    faCalendarWeek,
    faFlagCheckered,
} from "@fortawesome/free-solid-svg-icons";
import "devextreme/dist/css/dx.common.css";
import { loadMessages, locale } from "devextreme/localization";
import numeral from "numeral";
import "numeral/locales/de";
import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import { RouterProvider, createHashRouter, Outlet } from "react-router-dom";
import { getLicense } from "./actions/licenseActions";
import GuestLayout from "./components/layout/guestLayout";
import Layout from "./components/layout/layout";
import ThemeSelector from "./components/layout/theme/themeSelector";
import "./css/dx.generic.dynamic.css";
import "./GGStyling.scss";
import i18n from "./i18n";
import store from "./store";
import deMessages from "devextreme/localization/messages/de.json";
import esMessages from "devextreme/localization/messages/es.json";
import frMessages from "devextreme/localization/messages/fr.json";
import elMessages from "devextreme/localization/messages/el.json";
import csMessages from "devextreme/localization/messages/cs.json";
import fiMessages from "devextreme/localization/messages/fi.json";
import huMessages from "devextreme/localization/messages/hu.json";
import itMessages from "devextreme/localization/messages/it.json";
import jaMessages from "devextreme/localization/messages/ja.json";
import nlMessages from "devextreme/localization/messages/nl.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import ruMessages from "devextreme/localization/messages/ru.json";
import slMessages from "devextreme/localization/messages/sl.json";
import svMessages from "devextreme/localization/messages/sv.json";
import trMessages from "devextreme/localization/messages/tr.json";
import viMessages from "devextreme/localization/messages/vi.json";
import zhMessages from "devextreme/localization/messages/zh.json";
import "moment/locale/ar";
import "moment/locale/ca";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/ga";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/ro";
import "moment/locale/ru";
import "moment/locale/sl";
import "moment/locale/sw";
import "moment/locale/uk";
import dxButton from "devextreme/ui/button";
import * as Sentry from "@sentry/react";
import SentryURLWrapper from "./helpers/sentryURLWrapper";
import { FacebookSDKProvider } from "./components/pages/marketing/context/FacebookSDKContext";
import { getSettings } from "./actions/settingActions";

library.add(faUndo);
library.add(faArrowAltCircleLeft);
library.add(faArrowAltCircleRight);
library.add(faHome);
library.add(faPrint);
library.add(faMinus);
library.add(faPlus);
library.add(faTrashAlt);
library.add(faFileImport);
library.add(faRedoAlt);
library.add(faStream);
library.add(faBars);
library.add(faBook);
library.add(faEgg);
library.add(faPrescriptionBottle);
library.add(faCapsules);
library.add(faLink);
library.add(faSortAmountDown);
library.add(faTag);
library.add(faLayerGroup);
library.add(faStar);
library.add(faRuler);
library.add(faPaperclip);
library.add(faHeart);
library.add(faDatabase);
library.add(faMoneyBillAlt);
library.add(faPlusCircle);
library.add(faFolderPlus);
library.add(faRandom);
library.add(faInfoCircle);
library.add(faUtensilSpoon);
library.add(faNetworkWired);
library.add(faList);
library.add(faSeedling);
library.add(faListUl);
library.add(faPercent);
library.add(faImage);
library.add(faCalendarWeek);
library.add(faFlagCheckered);

dxButton.defaultOptions({
    options: {
        elementAttr: { class: "dx-button-custom" },
    },
});

// Sentry
Sentry.init({
    dsn: "https://852fd4499c0145d72e768e903f58777d@o4508773334253568.ingest.de.sentry.io/4508777587474512",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    normalizeDepth: 10, // The maximum depth to normalize the replay DOM tree to
    environment: process.env.NODE_ENV === "production" ? "production" : "debug",
});

// URL-correction Middleware / Ensure that a # appears in the URL
const ensureHashUrl = () => {
    if (!window.location.hash && window.location.pathname !== "/") {
        const targetPath = window.location.pathname;
        // Remove leading slash and redirect to hash URL
        const cleanPath = targetPath.replace(/^\//, "");
        window.location.replace(`/#/${cleanPath}`);
        return true;
    }
    return false;
};

// Router-Configuration
const router = createHashRouter([
    {
        element: <SentryURLWrapper>{ensureHashUrl() ? null : <Outlet />}</SentryURLWrapper>,
        children: [
            {
                path: "/guest/*",
                element: <GuestLayout />,
            },
            {
                path: "/*",
                element: <Layout />,
            },
        ],
    },
]);

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldReload: false,
        };

        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
            if (user.selectedLanguage) {
                switch (user.selectedLanguage) {
                    case "de":
                        loadMessages(deMessages);
                        locale("de");
                        break;
                    case "es":
                        loadMessages(esMessages);
                        locale("es");
                        break;
                    case "fr":
                        loadMessages(frMessages);
                        locale("fr");
                        break;
                    case "gr":
                        loadMessages(elMessages);
                        locale("el");
                        break;
                    case "cz":
                        loadMessages(csMessages);
                        locale("cs");
                        break;
                    case "fi":
                        loadMessages(fiMessages);
                        locale("fi");
                        break;
                    case "hu":
                        loadMessages(huMessages);
                        locale("hu");
                        break;
                    case "it":
                        loadMessages(itMessages);
                        locale("it");
                        break;
                    case "ja":
                        loadMessages(jaMessages);
                        locale("ja");
                        break;
                    case "nl":
                        loadMessages(nlMessages);
                        locale("nl");
                        break;
                    case "po":
                        loadMessages(ptMessages);
                        locale("pt");
                        break;
                    case "ru":
                        loadMessages(ruMessages);
                        locale("ru");
                        break;
                    case "sl":
                        loadMessages(slMessages);
                        locale("sl");
                        break;
                    case "sw":
                        loadMessages(svMessages);
                        locale("sv");
                        break;
                    case "tu":
                        loadMessages(trMessages);
                        locale("tr");
                        break;
                    case "vi":
                        loadMessages(viMessages);
                        locale("vi");
                        break;
                    case "ch":
                        loadMessages(zhMessages);
                        locale("zh");
                        break;
                    default:
                        break;
                }
            }
        }
    }

    componentDidMount() {
        numeral.locale("de");
        window.addEventListener("userChanged", this.handleUserChanged);
        store.dispatch(getLicense());
    }

    componentWillUnmount() {
        window.removeEventListener("userChanged", this.handleUserChanged);
    }

    handleUserChanged = () => {
        const user = localStorage.getItem("user");

        const language = user ? JSON.parse(user).selectedLanguage : "de";
        i18n.changeLanguage(language)
            .then(() => {
                // console.log(`Language changed to: ${language}`);
            })
            .catch((err) => {
                console.error("Error changing language:", err);
            });
    };

    render() {
        return (
            <FacebookSDKProvider>
                <I18nextProvider i18n={i18n}>
                    <ThemeSelector>
                        <RouterProvider router={router} />
                    </ThemeSelector>
                </I18nextProvider>
            </FacebookSDKProvider>
        );
    }
}
