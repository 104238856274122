import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";
import { cacheArticles } from "./menuCardAction";
import notify from "devextreme/ui/notify";
import { getToast } from "../helpers/requestHelpers";
import i18next from "i18next";

const config = new Config();

export const SET_LOADING = "SET_LOADING";
export function setLoading(isLoading) {
    return {
        type: SET_LOADING,
        payload: isLoading,
    };
}

export const SET_SESSION_ID = "SET_SESSION_ID";
export function setSessionId(sessionId) {
    return {
        type: SET_SESSION_ID,
        payload: sessionId,
    };
}

//NEU
export const SET_TEMP_ORDER_FOR_PAYMENT_PROCESS = "SET_TEMP_ORDER_FOR_PAYMENT_PROCESS";
export function setTempOrderForPaymentProcess(tempOrder) {
    return {
        type: SET_TEMP_ORDER_FOR_PAYMENT_PROCESS,
        payload: tempOrder,
    };
}

export function createReceiptEmail(transactionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify(transactionId),
        };
        console.log(requestOptions);
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/createReceiptEmail`, requestOptions).then((response) => handleResponse(response));
    };
}

export function createOrder(orderTypesId) {
    return function (dispatch) {
        dispatch(setLoading(true));
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/createOrder/${orderTypesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setOrder(json));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export function getCustomerForOrder(orderId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getCustomer/${orderId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function addArticleToOrder(ordersId, articlesId, cartItemId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/addArticleToOrder/${ordersId}/${articlesId}`,
            requestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    dispatch(deleteTempTransaction(cartItemId));
                }
                return handleResponse(response);
            })
            .then((json) => {
                return json;
            });
    };
}

export function changeCartItemAmount(orderId, transactionLineItemId, change) {
    return function (dispatch) {
        if (change > 0) {
            dispatch(incrementCartItemAmount(transactionLineItemId));
        } else if (change < 0) {
            dispatch(decrementCartItemAmount(transactionLineItemId));
        }
    };
}

export const INCREMENT_CART_ITEM_AMOUNT = "INCREMENT_CART_ITEM_AMOUNT";
export function incrementCartItemAmount(transactionLineItemId) {
    return {
        type: INCREMENT_CART_ITEM_AMOUNT,
        payload: transactionLineItemId,
    };
}

export const DECREMENT_CART_ITEM_AMOUNT = "DECREMENT_CART_ITEM_AMOUNT";
export function decrementCartItemAmount(transactionLineItemId) {
    return {
        type: DECREMENT_CART_ITEM_AMOUNT,
        payload: transactionLineItemId,
    };
}

export const DUPLICATE_CART_ITEM = "DUPLICATE_CART_ITEM";
export function duplicateCartItem(transactionLineItemId) {
    return function (dispatch) {
        dispatch({
            type: DUPLICATE_CART_ITEM,
            payload: transactionLineItemId,
        });
        return Promise.resolve();
    };
}

export function checkOrderDependenciesFulfilled(orderId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(orderId),
        };
        return fetch(config.backendHost + "/transactions/checkOrderDependenciesFulfilled", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateOrderValidation(json));
            });
    };
}

export function getTransaction(transactionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/transactions/getTransaction/" + transactionId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function createTransaction(orderTypesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(orderTypesId),
    };
    return function (dispatch) {
        dispatch(setLoading(true));
        return fetch(config.backendHost + "/transactions/createTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                json.Transactions = [];
                dispatch(setOrder(json));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export function updateTransactionComment(transactionId, comment) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ transactionId: transactionId, comment: comment }),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/transactions/updateTransactionComment`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllTransactions(loadOptions, daysBefore) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(daysBefore),
        };

        let query = new URLSearchParams(loadOptions).toString();

        return fetch(config.backendHost + "/transactions/getAllTransactions?" + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getTransactionById(transactionId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(transactionId),
        };
        return fetch(config.backendHost + "/transactions/getTransactionById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function cancelTransaction(transactionId, reason) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: transactionId, reason: reason }),
        };
        return fetch(config.backendHost + "/transactions/cancelTransaction", requestOptions).then((response) =>
            handleResponse(response).then(() => {
                dispatch(unsetOrder());
            })
        );
    };
}

export function bookAndSetDoneTransaction(transactionId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(transactionId),
        };
        return fetch(config.backendHost + "/transactions/bookAndSetDoneTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const SET_ORDER = "SET_ORDER";
export function setOrder(json) {
    return {
        type: SET_ORDER,
        payload: json,
    };
}

export function createDailyOrdersReport(date) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(date),
        };
        return fetch(config.backendHost + "/Reports/printDailyOrders", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getArticleGroupsForOrderManagement() {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagement", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getArticleGroupsForOrderManagementByParentId(articleGroupsId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        if (articleGroupsId === null) {
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagementByParentId/0",
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    return json;
                });
        } else {
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagementByParentId/" + articleGroupsId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    return json;
                });
        }
    };
}

export function getRootArticleGroupsForOrderManagement() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getRootArticleGroupsForOrderManagement", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function cancelOrder(ordersId, reason, sessionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ ordersId: ordersId, reason: reason }),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/cancelOrder?sessionId=${sessionId}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export const UNSET_ORDER = "UNSET_ORDER";
export function unsetOrder() {
    return function (dispatch) {
        return new Promise((resolve) => {
            dispatch({
                type: UNSET_ORDER,
            });
            setTimeout(() => {
                resolve();
            }, 0);
        });
    };
}

export function getOrders(daysAgo, page = 1, pageSize = 20, orderId = null, orderState = null) {
    return function (dispatch) {
        const params = new URLSearchParams({
            daysAgo: daysAgo.toString(),
            page: page.toString(),
            pageSize: pageSize.toString(),
        });
        if (orderId) {
            params.append("orderId", orderId.toString());
        }
        if (orderState) {
            params.append("orderState", orderState);
        }
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getOrders?` + params.toString(), requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getOpenOrders() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getOpenOrders`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getNewOrdersCountByTimestamp(lastOrdersLoadTimestamp) {
    return function () {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getNewOrdersCountByTimestamp/${lastOrdersLoadTimestamp}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function saveOrder(tempOrder, sessionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify(tempOrder),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/saveOrder?sessionId=${sessionId}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return handleResponse(response);
            })
            .then((json) => {
                return json;
            })
            .catch((error) => {
                notify(getToast(i18next.t("OrderManagement.OrderSaveError"), "error"));
                throw error;
            });
    };
}

export function lockOrder(orderId, sessionId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ sessionId: sessionId }),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/lockOrder/${orderId}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response;
                }
                return handleResponse(response).then((data) => {
                    return response;
                });
            })
            .catch((error) => {
                console.error("Fehler beim Sperren der Bestellung:", error);
                throw error;
            });
    };
}

export function unlockOrder(orderId, sessionId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ sessionId: sessionId }),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/unlockOrder/${orderId}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response;
                }
                return handleResponse(response).then((data) => {
                    return response;
                });
            })
            .catch((error) => {
                console.error("Fehler beim Entsperren der Bestellung:", error);
                throw error;
            });
    };
}

export function getOrder(orderId, sessionId, signal) {
    return function () {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
            signal: signal,
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getOrder/${orderId}?sessionId=${sessionId ?? ""}`,
            requestOptions
        )
            .then((response) => {
                if (signal && signal.aborted) {
                    throw new Error("Request was aborted");
                }
                return handleResponse(response);
            })
            .then((json) => {
                if (signal && signal.aborted) {
                    throw new Error("Request was aborted");
                }
                return json;
            })
            .catch((error) => {
                if (error.name === "AbortError") {
                    console.log("Fetch aborted");
                    throw error;
                }
                throw error;
            });
    };
}

export function getAllOrders(loadOptions, daysBefore) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(daysBefore),
        };

        let query = new URLSearchParams(loadOptions).toString();

        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getAllOrders?` + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrderDetailsSortedForPrint() {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getAllOrderDetailsSortedForPrint", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setDoneOrder(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/setDoneOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function bookAndSetDoneOrder(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/bookAndSetDoneOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrderDetails() {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getAllOrderDetails", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getOrderById(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/getOrderById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setPackageTerminalPrintedFlagTrue(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/setPackageTerminalPrintedFlagTrue", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllDailyOrderDetails(date) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(date ? date : new Date()),
        };
        return fetch(config.backendHost + "/Orders/getDailyOrderDetails", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const UPDATE_ORDER_VALIDATION = "UPDATE_ORDER_VALIDATION";
export function updateOrderValidation(orderValidation) {
    return {
        type: UPDATE_ORDER_VALIDATION,
        payload: orderValidation,
    };
}

export function doOrder(ordersId, addressId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, addressId: addressId }),
        };
        return fetch(config.backendHost + "/Orders/doOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(unsetOrder());
                return json;
            });
    };
}

export function setDeliveryAddressForOrder(orderId, addressId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId: orderId, addressId: addressId }),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/setDeliveryAddressForOrder`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateCustomerAddressForOrder(json));
                return json;
            });
    };
}

export function getRequiresCustomer(orderTypesId) {
    return function () {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getRequiresCustomer/${orderTypesId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const UPDATE_CUSTOMER_FOR_ORDER = "UPDATE_CUSTOMER_FOR_ORDER";
export function updateCustomerForOrder(json) {
    return {
        type: UPDATE_CUSTOMER_FOR_ORDER,
        payload: json,
    };
}

export const UNSET_CUSTOMER_FOR_ORDER = "UNSET_CUSTOMER_FOR_ORDER";
export function unsetCustomerForOrder() {
    return {
        type: UNSET_CUSTOMER_FOR_ORDER,
    };
}

export const UPDATE_CUSTOMER_ADDRESS_FOR_ORDER = "UPDATE_CUSTOMER_ADDRESS_FOR_ORDER";
export function updateCustomerAddressForOrder(json) {
    return {
        type: UPDATE_CUSTOMER_ADDRESS_FOR_ORDER,
        payload: json,
    };
}

export const UPDATE_EXTRA_AMOUNTS = "UPDATE_EXTRA_AMOUNTS";
export function updateExtraAmounts(country) {
    return {
        type: UPDATE_EXTRA_AMOUNTS,
        payload: country,
    };
}

export const UPDATE_MIN_ORDER_VALUE = "UPDATE_MIN_ORDER_VALUE";
export function updateMinOrderValue(minOrderValue) {
    return {
        type: UPDATE_MIN_ORDER_VALUE,
        payload: minOrderValue,
    };
}

export const UPDATE_MIN_ORDER_CHARGE = "UPDATE_MIN_ORDER_CHARGE";
export function updateMinOrderCharge(minOrderCharge) {
    return {
        type: UPDATE_MIN_ORDER_CHARGE,
        payload: minOrderCharge,
    };
}

export const UPDATE_DELIVER_LUMP_SUM = "UPDATE_DELIVER_LUMP_SUM";
export function updateDeliverLumpSum(deliverLumpSum) {
    return {
        type: UPDATE_DELIVER_LUMP_SUM,
        payload: deliverLumpSum,
    };
}

export const UPDATE_PAYMENT_FEE = "UPDATE_PAYMENT_FEE";
export function updatePaymentFee(paymentFee) {
    return {
        type: UPDATE_PAYMENT_FEE,
        payload: paymentFee,
    };
}

export const UPDATE_TOTAL_DISCOUNT = "UPDATE_TOTAL_DISCOUNT";
export function updateTotalDiscount(totalDiscount) {
    return {
        type: UPDATE_TOTAL_DISCOUNT,
        payload: totalDiscount,
    };
}

export const UPDATE_SUM_TO_PAY = "UPDATE_SUM_TO_PAY";
export function updateSumToPay(sumToPay) {
    return {
        type: UPDATE_SUM_TO_PAY,
        payload: sumToPay,
    };
}

export function createCustomer(customerFormData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify(customerFormData),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/createCustomer`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateCustomer(customerFormData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify(customerFormData),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/updateCustomer`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setCustomerDiscount(customerId, discount) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ customerId: customerId, discount: discount }),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/setCustomerDiscount`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const UPDATE_ORDER_COMMENT = "UPDATE_ORDER_COMMENT";
export function updateOrderComment(comment) {
    return {
        type: UPDATE_ORDER_COMMENT,
        payload: comment,
    };
}

export const SET_TRANSACTION = "SET_TRANSACTION";
export function setTransaction(transaction) {
    return {
        type: SET_TRANSACTION,
        payload: transaction,
    };
}

export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";
export function updateTransactions(transactions) {
    return {
        type: UPDATE_TRANSACTIONS,
        payload: transactions,
    };
}

export const ADD_ARTICLE_TO_CART = "ADD_ARTICLE_TO_CART";
export function addArticleToCart(transactionLineItem) {
    return {
        type: ADD_ARTICLE_TO_CART,
        payload: transactionLineItem,
    };
}

export const ADD_ARTICLE_DEPENDENCY_TO_CART = "ADD_ARTICLE_DEPENDENCY_TO_CART";
export function addArticleDependencyToCart(transactionLineItem, selectedCartItemId) {
    return function (dispatch, getState) {
        return new Promise((resolve) => {
            dispatch({
                type: ADD_ARTICLE_DEPENDENCY_TO_CART,
                payload: { transactionLineItem, selectedCartItemId },
            });

            const newState = getState();
            const isItemAdded =
                newState.orderManagement.tempOrder.Transactions[0].TransactionDatas.TransactionLines.some(
                    (line) =>
                        line.TransactionLineItems.transactionLineItemId === selectedCartItemId &&
                        line.TransactionLineItems.other_TransactionLineItems.some(
                            (subItem) => subItem.transactionLineItemId === transactionLineItem.transactionLineItemId
                        )
                );

            if (isItemAdded) {
                // Resolve after the item has been added
                resolve();
            } else {
                // Wenn das Element nicht hinzugefügt wurde, können Sie hier einen Fehler werfen oder anders damit umgehen
                console.error("Artikel konnte nicht zum Warenkorb hinzugefügt werden");
                resolve();
            }
        });
    };
}

export const SET_SELECTED_DEPENDENCY_GROUP = "SET_SELECTED_DEPENDENCY_GROUP";
export function setSelectedDependencyGroup(dependencyGroup) {
    return {
        type: SET_SELECTED_DEPENDENCY_GROUP,
        payload: dependencyGroup,
    };
}

export const RECEIVE_ORDERTYPES_FOR_ORDER = "RECEIVE_ORDERTYPES_FOR_ORDER";
export function receiveOrderTypesForOrder(orderTypes) {
    return {
        type: RECEIVE_ORDERTYPES_FOR_ORDER,
        payload: orderTypes,
    };
}

export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export function setOrderType(orderType) {
    return {
        type: SET_ORDER_TYPE,
        payload: orderType,
    };
}

export const UPDATE_CURRENT_WEIGHT = "UPDATE_CURRENT_WEIGHT";
export function updateCurrentWeight(weight) {
    return {
        type: UPDATE_CURRENT_WEIGHT,
        payload: weight,
    };
}

export function createReceipt(orderId, orderTypeId, paymentTypesId, transactionLines, orderDiscounts, sessionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({
                orderId: orderId,
                orderTypeId: orderTypeId,
                paymentTypesId: paymentTypesId,
                transactionLines: transactionLines,
                orderDiscounts: orderDiscounts,
            }),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/createReceipt?sessionId=${sessionId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getOrderDetail(orderDetailsId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getOrderDetail/" + orderDetailsId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setOrderPaymentType(orderId, paymentTypesId, sessionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({
                orderId: orderId,
                paymentTypesId: paymentTypesId,
            }),
        };

        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/updateOrderPaymentType?sessionId=${sessionId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setOrderReceiptType(orderId, receiptType, sessionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({
                orderId: orderId,
                receiptType: receiptType,
            }),
        };

        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/updateReceiptType?sessionId=${sessionId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateOrderDetail(orderDetail) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                ordersId: orderDetail.ordersId,
                orderDetailsId: orderDetail.orderDetailsId,
                sizesId: orderDetail.sizesId,
                articleCount: orderDetail.articleCount,
            }),
        };
        return fetch(config.backendHost + "/Orders/updateOrderDetail", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function orderDetailSetArticleCount(orderDetail) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                ordersId: orderDetail.ordersId,
                orderDetailsId: orderDetail.orderDetailsId,
                amount: orderDetail.articleCount,
            }),
        };
        return fetch(config.backendHost + "/Orders/orderDetailSetArticleCount", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const UPDATE_MANUAL_DELIVERY_TIME = "UPDATE_MANUAL_DELIVERY_TIME";
export function updateManualDeliveryTime(manualDeliveryTimeMin, manualDeliveryTimeMax) {
    return {
        type: UPDATE_MANUAL_DELIVERY_TIME,
        payload: manualDeliveryTimeMin,
        manualDeliveryTimeMax,
    };
}

export const UPDATE_RECEIPT = "UPDATE_RECEIPT";
export function updateReceipt(receiptType) {
    return {
        type: UPDATE_RECEIPT,
        payload: receiptType,
    };
}

export const UPDATE_REQUESTED_DELIVERY_TIME = "UPDATE_REQUESTED_DELIVERY_TIME";
export function updateRequestedDeliveryTime(requestedDeliveryTime) {
    return {
        type: UPDATE_REQUESTED_DELIVERY_TIME,
        payload: requestedDeliveryTime,
    };
}

export const UPDATE_ORDER_PAYMENT_TYPE = "UPDATE_ORDER_PAYMENT_TYPE";
export function updateOrderPaymentType(paymentTypesId) {
    return {
        type: UPDATE_ORDER_PAYMENT_TYPE,
        payload: paymentTypesId,
    };
}

export const UPDATE_ORDER_DISCOUNT = "UPDATE_ORDER_DISCOUNT";
export function updateOrderDiscount(percentageDiscount, valueDiscount) {
    return {
        type: UPDATE_ORDER_DISCOUNT,
        payload: { percentageDiscount, valueDiscount },
    };
}

export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export function deleteCartItem(transactionLineItemId) {
    return function (dispatch) {
        dispatch({
            type: DELETE_CART_ITEM,
            payload: transactionLineItemId,
        });
        return Promise.resolve();
    };
}

export const DELETE_TEMP_TRANSACTION = "DELETE_TEMP_TRANSACTION";
export function deleteTempTransaction(cartItemId) {
    return {
        type: DELETE_TEMP_TRANSACTION,
        payload: cartItemId,
    };
}

export const UPDATE_CART_ITEM_PRICE = "UPDATE_CART_ITEM_PRICE";
export function updateCartItemPrice(transactionLineItemId, newPrice) {
    return {
        type: UPDATE_CART_ITEM_PRICE,
        payload: { transactionLineItemId, newPrice },
    };
}

export const UPDATE_SUB_ITEM_PRICE = "UPDATE_SUB_ITEM_PRICE";
export function updateSubItemPrice(transactionLineItemId, subItemId, newPrice) {
    return {
        type: UPDATE_SUB_ITEM_PRICE,
        payload: { transactionLineItemId, subItemId, newPrice },
    };
}

export function getCurrentDeliveryDay() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getCurrentDeliveryDay", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const SET_ARTICLES_LOADING = "SET_ARTICLES_LOADING";
export function setArticlesLoading(loading) {
    return {
        type: SET_ARTICLES_LOADING,
        payload: loading,
    };
}

export const CACHE_ARTICLE_GROUPS = "CACHE_ARTICLE_GROUPS";

export function cacheArticleGroups(parentId, articleGroups) {
    return { type: CACHE_ARTICLE_GROUPS, payload: { parentId, articleGroups } };
}

export const CACHE_ARTICLE_DEPENDENCY_GROUPS = "CACHE_ARTICLE_DEPENDENCY_GROUPS";

export function cacheArticleDependencyGroups(articlesId, articleDependencyGroups) {
    return { type: CACHE_ARTICLE_DEPENDENCY_GROUPS, payload: { articlesId, articleDependencyGroups } };
}

export function getArticleGroupsByParentId(articleGroupsId) {
    return function (dispatch, getState) {
        dispatch(updateCurrentArticleGroups([]));
        const cachedArticleGroups = getState().orderManagement.cachedArticleGroups;
        if (cachedArticleGroups[articleGroupsId ?? 0]) {
            dispatch(updateCurrentArticleGroups(cachedArticleGroups[articleGroupsId ?? 0]));
            return Promise.resolve(cachedArticleGroups[articleGroupsId ?? 0]);
        }

        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getArticleGroupsByParentId/${articleGroupsId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(cacheArticleGroups(articleGroupsId ?? 0, json));
                dispatch(updateCurrentArticleGroups(json));
                return json;
            });
    };
}

export function getArticlesByArticleGroupsId(articleGroupsId, forFree) {
    return function (dispatch, getState) {
        dispatch(updateCurrentArticles([]));
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        const cachedArticles = getState().orderManagement.cachedArticles;
        if (cachedArticles[articleGroupsId ?? 0]) {
            dispatch(updateCurrentArticles(cachedArticles[articleGroupsId ?? 0], forFree));
            return Promise.resolve(cachedArticles[articleGroupsId ?? 0]);
        }

        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getArticlesByArticleGroupsId/${articleGroupsId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(cacheArticles(articleGroupsId ?? 0, json));
                dispatch(updateCurrentArticles(json, forFree));
                return json;
            });
    };
}

export const UPDATE_SELECTED_ARTICLE_GROUP = "UPDATE_SELECTED_ARTICLE_GROUP";
export function updateSelectedArticleGroup(articleGroup) {
    return {
        type: UPDATE_SELECTED_ARTICLE_GROUP,
        payload: articleGroup,
    };
}

export const UPDATE_SELECTED_CART_ITEM_ID = "UPDATE_SELECTED_CART_ITEM_ID";
export function updateSelectedCartItemId(transactionLineItem) {
    return {
        type: UPDATE_SELECTED_CART_ITEM_ID,
        payload: transactionLineItem?.transactionLineItemId ?? null,
    };
}

export const UPDATE_CURRENT_ARTICLE_GROUPS = "UPDATE_CURRENT_ARTICLE_GROUPS";
export function updateCurrentArticleGroups(articleGroups) {
    return {
        type: UPDATE_CURRENT_ARTICLE_GROUPS,
        payload: articleGroups,
    };
}

export const UPDATE_ARTICLE_DEPENDENCIES_OF_TRANSACTION_LINE_ITEM =
    "UPDATE_ARTICLE_DEPENDENCIES_OF_TRANSACTION_LINE_ITEM";
export function updateArticleDependenciesOfTransactionLineItem(articleDependencyGroups, transactionLineItemId) {
    return {
        type: UPDATE_ARTICLE_DEPENDENCIES_OF_TRANSACTION_LINE_ITEM,
        payload: { articleDependencyGroups, transactionLineItemId },
    };
}

export const UPDATE_CURRENT_ARTICLES = "UPDATE_CURRENT_ARTICLES";
export function updateCurrentArticles(articles, forFree) {
    return {
        type: UPDATE_CURRENT_ARTICLES,
        payload: { articles, forFree },
    };
}

export const TOGGLE_SEARCH_POPUP = "TOGGLE_SEARCH_POPUP";
export function toggleSearchPopup(isSearchPopupOpen) {
    return {
        type: "TOGGLE_SEARCH_POPUP",
        payload: isSearchPopupOpen,
    };
}

export function getSearchResults(searchString) {
    return function (dispatch) {
        dispatch(setArticlesLoading(true));
        dispatch(updateCurrentArticleGroups([]));
        dispatch(updateCurrentArticles([]));
        dispatch(updateSelectedArticleGroup(null));
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getArticlesBySearchString/${searchString}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateCurrentArticles(json));
            })
            .then(() => {
                dispatch(setArticlesLoading(false));
            });
    };
}

export function getArticleDependencies(article, transactionLineItemId) {
    return function (dispatch, getState) {
        const cachedArticleDependencyGroups = getState().orderManagement.cachedArticleDependencyGroups;
        if (cachedArticleDependencyGroups[article.articlesId]) {
            const dependencies = cachedArticleDependencyGroups[article.articlesId];
            dispatch(updateArticleDependenciesOfTransactionLineItem(dependencies, transactionLineItemId));
            return Promise.resolve({
                hasDependencies: !!(
                    dependencies.dependencyArticleGroups?.length || dependencies.dependencyGroups?.length
                ),
                dependencies: dependencies,
            });
        }

        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/getArticleDependencies/${article.articlesId}`,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(cacheArticleDependencyGroups(article.articlesId, json));
                dispatch(updateArticleDependenciesOfTransactionLineItem(json, transactionLineItemId));
                return {
                    hasDependencies: !!(json.dependencyArticleGroups?.length || json.dependencyGroups?.length),
                    dependencies: json,
                };
            });
    };
}

export const UPDATE_TRANSACTION_LINE_ITEM_TOTAL_PRICE = "UPDATE_TRANSACTION_LINE_ITEM_TOTAL_PRICE";
export const updateTransactionLineItemTotalPrice = (transactionLineItemId, totalPrice) => ({
    type: UPDATE_TRANSACTION_LINE_ITEM_TOTAL_PRICE,
    payload: { transactionLineItemId, totalPrice },
});

export const UPDATE_SUB_ITEM_AMOUNT = "UPDATE_SUB_ITEM_AMOUNT";
export function updateSubItemAmount(transactionLineItemId, subItemId, amount) {
    return function (dispatch) {
        return new Promise((resolve) => {
            dispatch({
                type: UPDATE_SUB_ITEM_AMOUNT,
                payload: { transactionLineItemId, subItemId, amount },
            });
            resolve();
        });
    };
}

export const REMOVE_SUB_ITEM = "REMOVE_SUB_ITEM";
export function removeSubItem(transactionLineItemId, subItemId) {
    return {
        type: REMOVE_SUB_ITEM,
        payload: { transactionLineItemId, subItemId },
    };
}

export const UPDATE_ARTICLE_SIZE = "UPDATE_ARTICLE_SIZE";
export function updateArticleSize(transactionLineItemId, articleSize) {
    return {
        type: UPDATE_ARTICLE_SIZE,
        payload: { transactionLineItemId, articleSize },
    };
}

export const UPDATE_CART_ITEM_COMMENT = "UPDATE_CART_ITEM_COMMENT";
export function updateCartItemComment(transactionLineItemId, comment) {
    return {
        type: UPDATE_CART_ITEM_COMMENT,
        payload: { transactionLineItemId, comment },
    };
}

export const UPDATE_SUB_ITEM_COMMENT = "UPDATE_SUB_ITEM_COMMENT";
export function updateSubItemComment(transactionLineItemId, subItemId, comment) {
    return {
        type: UPDATE_SUB_ITEM_COMMENT,
        payload: { transactionLineItemId, subItemId, comment },
    };
}

export const UPDATE_ORDER_VALIDITY = "UPDATE_ORDER_VALIDITY";
export function updateOrderValidity(isValid, errors = []) {
    return {
        type: UPDATE_ORDER_VALIDITY,
        payload: { isValid, errors },
    };
}

export function checkOrderValidity() {
    return function (dispatch, getState) {
        const state = getState();
        const { tempOrder, selectedOrderType } = state.orderManagement;

        let isValid = true;

        // Check if items are available in the cart
        const transactionLineItems =
            tempOrder.Transactions?.flatMap((transaction) =>
                transaction.TransactionDatas.TransactionLines.map((line) => line.TransactionLineItems)
            ) || [];

        if (transactionLineItems.length === 0) {
            isValid = false;
        }

        // Check that all items are configured correctly
        const hasIncompleteItems = transactionLineItems.some((item) => hasWarning(item));
        if (hasIncompleteItems) {
            isValid = false;
        }

        // Additional check for customer selection and address
        if (selectedOrderType && selectedOrderType.requiresCustomer) {
            const customer = tempOrder.TransactionBuyers?.Customer || tempOrder.TransactionBuyers?.Customers;

            if (!customer) {
                isValid = false;
            }
        }

        dispatch(updateOrderValidity(isValid));
    };
}

// Help function for checking if an article has a warning
function hasWarning(transactionLineItem) {
    if (transactionLineItem.dependencyArticleGroups) {
        for (const group of transactionLineItem.dependencyArticleGroups) {
            if (group.mustAdd) {
                const summedQuantities = transactionLineItem.other_TransactionLineItems
                    .filter((item) => item.Articles.articleGroupsId === group.dependencyArticleGroup.articleGroupsId)
                    .reduce((sum, item) => sum + item.quantity, 0);

                if (summedQuantities < group.amount) {
                    return true;
                }
            }
        }
    }
    if (transactionLineItem.dependencyGroups) {
        for (const group of transactionLineItem.dependencyGroups) {
            if (group.mustAdd) {
                const summedQuantities = transactionLineItem.other_TransactionLineItems
                    .filter((item) => group.articles.some((article) => article.articlesId === item.Articles.articlesId))
                    .reduce((sum, item) => sum + item.quantity, 0);

                if (summedQuantities < group.amount) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const updateOrderTypes = (orderTypes) => ({
    type: "UPDATE_ORDER_TYPES",
    payload: orderTypes,
});

export const CANCELLATION_TRANSACTION_LINES = "CANCELLATION_TRANSACTION_LINES";
export function cancellationTransactionLines(transactionLinesIds, reason, cancellationItems) {
    return {
        type: CANCELLATION_TRANSACTION_LINES,
        payload: {
            cancellationTransactionLinesIds: transactionLinesIds,
            cancelReason: reason,
            cancellationItems: cancellationItems,
        },
    };
}

// New action to update final values
export function updateFinalOrderValues() {
    return function (dispatch, getState) {
        return new Promise((resolve) => {
            const state = getState();
            const { tempOrder } = state.orderManagement;

            const currentTransactionSum =
                tempOrder.Transactions?.reduce(
                    (sum, transaction) =>
                        sum +
                        transaction.TransactionDatas.TransactionLines.reduce(
                            (lineSum, line) => lineSum + Math.max(0, line.TransactionLineItems.total_price),
                            0
                        ),
                    0
                ) || 0;

            const deliveryFee = Number(tempOrder.deliverLumpSum || 0);
            const deliverFreeValue = Number(tempOrder.deliverFreeValue || 0);
            const minOrderValue = Number(tempOrder.minOrderValue || 0);
            const minOrderCharge = Number(tempOrder.minOrderCharge || 0);

            // Calculate final values
            const finalDeliveryFee =
                (deliveryFee > 0 && tempOrder.deliveryNeverFree) ||
                (deliveryFee > 0 && deliverFreeValue > 0 && currentTransactionSum < deliverFreeValue) ||
                (deliveryFee > 0 && deliverFreeValue === 0)
                    ? deliveryFee
                    : 0;

            const finalMinOrderCharge = currentTransactionSum < minOrderValue && minOrderCharge ? minOrderCharge : 0;

            // Update tempOrder with final values
            const updatedTempOrder = {
                ...tempOrder,
                deliverLumpSum: finalDeliveryFee,
                minOrderCharge: finalMinOrderCharge,
            };

            dispatch({
                type: "UPDATE_FINAL_ORDER_VALUES",
                payload: {
                    deliverLumpSum: finalDeliveryFee,
                    minOrderCharge: finalMinOrderCharge,
                },
            });

            // Return updated tempOrder object
            resolve(updatedTempOrder);
        });
    };
}
