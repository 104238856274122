import Config from "../Config";
import { authHeaderLicman, handlePossibleUnauthorizedResponse, handleResponse } from "../helpers/requestHelpers";
import store from "../store";

const config = new Config();

export const REQUEST_MEDIAFOLDER = "REQUEST_MEDIAFOLDER";
export function requestMediaFolder() {
    return {
        type: REQUEST_MEDIAFOLDER,
    };
}

export const RECEIVE_MEDIAFOLDER = "RECEIVE_MEDIAFOLDER";
export function receiveMediaFolder(json) {
    return {
        type: RECEIVE_MEDIAFOLDER,
        mediaFolder: json,
    };
}

export function createMediaFolder(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.licmanModule}/createMediaFolder`, requestOptions).then((response) =>
            handlePossibleUnauthorizedResponse(response)
        );
    };
}

export function getRootFoldersForLicenseNumber() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderLicman(),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getRootFoldersForLicenseNumber`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getFoldersByLicense() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderLicman(),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getFoldersByLicense`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getMediaFolderById(mediaFolderId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify({ mediaFolderId: mediaFolderId }),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMediaFolderById`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}

export function getChildrenById(mediaFolderId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify({ mediaFolderId: mediaFolderId }),
    };

    return function (dispatch) {
        dispatch(requestMediaFolder);
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMediaFolderChildrenById`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}

export function updateMediaFolder(mediaFolder) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderLicman(),
        body: JSON.stringify(mediaFolder),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/updateMediaFolder`, requestOptions)
            .then((response) => handlePossibleUnauthorizedResponse(response))
            .then((json) => json);
    };
}

export function getMediaFoldersContains(searchString) {
    let encodedSearchString = encodeURIComponent(searchString.toLowerCase());
    const requestOptions = {
        method: "GET",
        headers: authHeaderLicman(),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/getMediaFolderByString/${encodedSearchString}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function deleteMediaFolder(mediaFolderId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeaderLicman(),
        body: JSON.stringify({ mediaFolderId: mediaFolderId }),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.licmanModule}/deleteMediaFolder`, requestOptions)
        .then((response) => handlePossibleUnauthorizedResponse(response));
    };
}

export async function generateUniqueFolderName(name, existingNames) {
    if (!existingNames.includes(name.toLowerCase())) {
        return name;
    }

    const baseName = name.match(/^(.*?)-\d+$/) ? name.replace(/-\d+$/, "") : name;
    let counter = 1;

    while (existingNames.includes(baseName.toLowerCase() + "-" + counter)) {
        counter++;
    }
    return `${baseName}-${counter}`;
}

export async function getExistingFolderNames(parentMediaFolderId = 0) {
    if (parentMediaFolderId !== 0) {
        const children = await store.dispatch(getChildrenById(parentMediaFolderId));
        return children.map((m) => m.name.toLowerCase());
    } else {
        const response = await store.dispatch(getRootFoldersForLicenseNumber());
        return response.map((m) => m.name.toLowerCase());
    }
}
