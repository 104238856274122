import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNavigationFavorites } from "../../../../actions/userActions";
import EditFavoritesPopup from "./editFavoritesPopup";
import "./navigationPage.scss";
import { getSettings } from "../../../../actions/settingActions";
import { unsetOrder } from "../../../../actions/orderManagementActions";

const NavigationPage = memo(({ page }) => {
    const [showEditFavoritesPopup, setShowEditFavoritesPopup] = useState(false);
    const [allNavigationTiles, setAllNavigationTiles] = useState();
    const [favoriteTiles, setFavoriteTiles] = useState();

    const { t } = useTranslation(["dynamicTranslation"]);
    const dispatch = useDispatch();
    const identity = useSelector((state) => state.identity);
    const navigation = useSelector((state) => state.navigation);
    const navigate = useNavigate();

    const toggleFavoritesPopup = useCallback(() => {
        setShowEditFavoritesPopup((prev) => !prev);
    }, []);

    const updateTiles = useCallback(() => {
        const favorites = identity.user.Favorites;
        let newFavoriteTiles;
        let newAllNavigationTiles = [];

        navigation.pages.forEach((page) => {
            if (page.navigationTiles) {
                newAllNavigationTiles = newAllNavigationTiles.concat(page.navigationTiles);
            }
        });

        if (favorites) {
            newFavoriteTiles = newAllNavigationTiles.filter((tile) =>
                favorites.some((element) => element.route === tile.route)
            );
        }

        setAllNavigationTiles(newAllNavigationTiles);
        setFavoriteTiles(newFavoriteTiles);
    }, [identity.user.Favorites, navigation.pages]);

    useEffect(() => {
        if (!identity.user.Favorites) {
            dispatch(getNavigationFavorites(identity.user.Favorites));
        } else {
            updateTiles();
        }
    }, [identity.user.Favorites, dispatch, updateTiles]);

    useEffect(() => {
        dispatch(getSettings());
    }, [dispatch]);

    // Define special routes and their actions
    const specialRouteActions = {
        "/auftraege/erfassen": () => dispatch(unsetOrder()),
        // here you can add more special routes and their actions
        // '/andere/route': () => dispatch(andereAktion())
    };

    const handleNavigation = useCallback(
        (route) => {
            if (!route) return;

            // Check if the route starts with one of the special routes
            const specialRoute = Object.keys(specialRouteActions).find((specialRoute) =>
                route.startsWith(specialRoute)
            );

            if (specialRoute) {
                // Execute the associated action
                specialRouteActions[specialRoute]();
            }

            navigate(route);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, navigate]
    );

    const renderNavigationTile = useCallback(
        (tile, index) => {
            if (!tile.hasRight || tile.hideTile) {
                return <React.Fragment key={index} />;
            }

            return (
                <div
                    key={index}
                    className={`navigationTile ${tile.hasRight ? "" : "disabledTile"}`}
                    onClick={() => tile.route && handleNavigation(tile.route)}
                    role="button"
                    tabIndex={0}
                >
                    <tile.icon icon={tile.iconText || ""} />
                    <span>{t(tile.title)}</span>
                </div>
            );
        },
        [t, handleNavigation]
    );

    return (
        <React.Fragment>
            <div className="content" style={{ height: "100%" }}>
                <div
                    className="tilesContainerWrapper"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <div id="tilesContainer">
                            <div className="tiles">
                                <header>{t(page.title)}</header>
                                <div className="revealed">{page?.navigationTiles?.map(renderNavigationTile)}</div>
                            </div>
                        </div>

                        <div id="tilesContainer">
                            <div className="tiles">
                                <div className="revealed">
                                    {favoriteTiles && (
                                        <React.Fragment>
                                            <header>
                                                {t("Favoriten")}
                                                <IconButton
                                                    title={t("Favoriten bearbeiten")}
                                                    onClick={toggleFavoritesPopup}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </header>
                                            {favoriteTiles.filter((tile) => tile.hasRight).map(renderNavigationTile)}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showEditFavoritesPopup && (
                <EditFavoritesPopup
                    visible={showEditFavoritesPopup}
                    allNavigationTiles={allNavigationTiles}
                    favoriteTiles={favoriteTiles}
                    togglePopup={toggleFavoritesPopup}
                    update={updateTiles}
                />
            )}
        </React.Fragment>
    );
});

NavigationPage.displayName = "NavigationPage";

export default NavigationPage;
