import Config from "../Config";
import { authHeaderForFGFunctions, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getOffers() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/getAllOffers", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getOfferById(offerId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + `/getOfferById/${offerId}`, requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function deleteOffer(offerId) {
    return function (dispatch) {
        const requestOptions = {
            method: "DELETE",
            headers: authHeaderForFGFunctions(),
            body: JSON.stringify({ offerId }),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + `/deleteOffer/${offerId}`, requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updateOffer(offer) {
    return function (dispatch) {
        const requestOptions = {
            method: "PUT",
            headers: authHeaderForFGFunctions(),
            body: JSON.stringify(offer),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/updateOffer", requestOptions).then((response) => {
            const status = response.status;
            return handleResponse(response).then((data) => ({
                status,
                data,
            }));
        });
    };
}

export function getOfferTypes() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/getOfferTypes", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getOrderTypes() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/getOrderTypes", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getArticlesForOffer(orderTypesId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(
            `${config.apiUrl}/${config.marketingModule}/getArticlesForOffer/${orderTypesId}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function getDishesForOffer(orderTypesId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(
            `${config.apiUrl}/${config.marketingModule}/getDishesForOffer/${orderTypesId}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function createOffer(offer) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForFGFunctions(),
            body: JSON.stringify(offer),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/createOffer", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getWeeklyPlans() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/getWeeklyPlans", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const setDrawerOpen = (isOpen) => ({
    type: SET_DRAWER_OPEN,
    payload: isOpen,
});

export function getArticleGroups() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForFGFunctions(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getArticleGroups`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function createArticleFromOffer(articleName, articleGroupsId, orderTypesId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForFGFunctions(),
            body: JSON.stringify({ name: articleName, articleGroupsId, orderTypesId }),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/createArticle`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function createArticleSizeFromOffer(articlesId, sizeName, portionSize) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForFGFunctions(),
            body: JSON.stringify({ articlesId, sizeName, portionSize }),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/createArticleSize`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export const RESET_OFFER_STATE = "RESET_OFFER_STATE";
export function resetOfferState() {
    return {
        type: RESET_OFFER_STATE,
    };
}

export const SET_OFFERS = "SET_OFFERS";
export function setOffers(offers) {
    return {
        type: SET_OFFERS,
        payload: offers,
    };
}

export const SET_IS_LOADING = "SET_IS_LOADING";
export function setIsLoading(isLoading) {
    return {
        type: SET_IS_LOADING,
        payload: isLoading,
    };
}

export const SET_SELECTED_OFFER = "SET_SELECTED_OFFER";
export function setSelectedOffer(offerId) {
    return {
        type: SET_SELECTED_OFFER,
        payload: offerId,
    };
}

export const SET_SHOW_OFFER_EDITOR = "SET_SHOW_OFFER_EDITOR";
export const setShowOfferEditor = (show) => ({
    type: SET_SHOW_OFFER_EDITOR,
    payload: show,
});

export const SET_SHOW_DELETE_CONFIRMATION = "SET_SHOW_DELETE_CONFIRMATION";
export function setShowDeleteConfirmation(show) {
    return {
        type: SET_SHOW_DELETE_CONFIRMATION,
        payload: show,
    };
}

export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";
export const setValidationErrors = (errors) => ({
    type: SET_VALIDATION_ERRORS,
    payload: errors,
});

// Step 1 - Basic Data
export const SET_OFFER_TYPES = "SET_OFFER_TYPES";
export const setOfferTypes = (offerTypes) => ({
    type: SET_OFFER_TYPES,
    payload: offerTypes,
});

export const SET_ORDER_TYPES = "SET_ORDER_TYPES";
export const setOrderTypes = (orderTypes) => ({
    type: SET_ORDER_TYPES,
    payload: orderTypes,
});

export const UPDATE_TIME_PERIOD_DATA = "UPDATE_TIME_PERIOD_DATA";
export const updateTimePeriodData = (updates) => ({
    type: UPDATE_TIME_PERIOD_DATA,
    payload: updates,
});

export const UPDATE_BASIC_DATA = "UPDATE_BASIC_DATA";
export const updateBasicData = (updates) => ({
    type: UPDATE_BASIC_DATA,
    payload: updates,
});

// Step 2 - Template
export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";
export function setSelectedTemplate(template) {
    return {
        type: SET_SELECTED_TEMPLATE,
        payload: template,
    };
}

export const SET_TEMPLATE_PREVIEWS = "SET_TEMPLATE_PREVIEWS";
export function setTemplatePreviews(previews) {
    return {
        type: SET_TEMPLATE_PREVIEWS,
        payload: previews,
    };
}

export const SET_IS_LOADING_PREVIEWS = "SET_IS_LOADING_PREVIEWS";
export function setIsLoadingPreviews(isLoading) {
    return {
        type: SET_IS_LOADING_PREVIEWS,
        payload: isLoading,
    };
}

// Step 3 - Products
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
export const setSelectedProduct = (product) => ({
    type: SET_SELECTED_PRODUCT,
    payload: product,
});

export const SET_OFFER_PRODUCTS = "SET_OFFER_PRODUCTS";
export const setOfferProducts = (products) => ({
    type: SET_OFFER_PRODUCTS,
    payload: products,
});

export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const setSelectedCategory = (category) => ({
    type: SET_SELECTED_CATEGORY,
    payload: category,
});

export const SET_SELECTED_SIZE = "SET_SELECTED_SIZE";
export const setSelectedSize = (size) => ({
    type: SET_SELECTED_SIZE,
    payload: size,
});

export const SET_SELECTED_DAYS = "SET_SELECTED_DAYS";
export const setSelectedDays = (days) => ({
    type: SET_SELECTED_DAYS,
    payload: days,
});

export const SET_SELECTED_PRICE = "SET_SELECTED_PRICE";
export const setSelectedPrice = (price) => ({
    type: SET_SELECTED_PRICE,
    payload: price,
});

export const SET_PRODUCT_TO_EDIT = "SET_PRODUCT_TO_EDIT";
export const setProductToEdit = (product) => ({
    type: SET_PRODUCT_TO_EDIT,
    payload: product,
});

// Step 4 - Media
export const TOGGLE_FORMAT_SELECTION = "TOGGLE_FORMAT_SELECTION";
export const toggleFormatSelection = (format) => ({
    type: TOGGLE_FORMAT_SELECTION,
    payload: format,
});

// Step 5 - Schedule
export const UPDATE_CAMPAIGN_PERIOD = "UPDATE_CAMPAIGN_PERIOD";
export const updateCampaignPeriod = (updates) => ({
    type: UPDATE_CAMPAIGN_PERIOD,
    payload: updates,
});

export const SET_SELECTED_POST = "SET_SELECTED_POST";
export const setSelectedPost = (post) => ({
    type: SET_SELECTED_POST,
    payload: post,
});
