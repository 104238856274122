import { Button, DataGrid, ScrollView, Switch } from "devextreme-react";
import { Column, Editing, Sorting } from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AllCookingProcessesDatagrid from "./allCookingProcessesDatagrid";
import IngredientsDatagrid from "./ingredientsDatagrid";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../helpers/requestHelpers";

class CreateCookingProcessPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amountForSizes: new Map(),
            updating: false,
            switchedOn: true,
        };

        this.dataGridArticleSizesWithoutDefaultSize = React.createRef();
        this.dataGridParentCookingProcess = React.createRef();
        this.dataGridIngredients = React.createRef();
        this.dataGridChildCookingProcesses = React.createRef();
        this.handleSave = this.handleSave.bind(this);
        this.getAmountPerSize = this.getAmountPerSize.bind(this);
        this.onSwitchOptionChanged = this.onSwitchOptionChanged.bind(this);
        this.childCookingProcessExistingWeightUpdated = this.childCookingProcessExistingWeightUpdated.bind(this);
    }

    getAmountPerSize(e) {
        var amountPerSize = this.state.amountForSizes.get(e.data.Size.sizesId);
        return amountPerSize;
    }

    onSwitchOptionChanged(e) {
        if (e.name === "value") {
            this.setState({
                switchedOn: e.value,
            });
        }
    }

    childCookingProcessExistingWeightUpdated() {
        this.setState({
            update: !this.state.update,
        });
    }

    render() {
        const { t } = this.props;
        return (
            <Popup
                visible={this.props.visible !== undefined}
                onHiding={this.props.togglePopup}
                hideOnOutsideClick={true}
                showCloseButton={true}
                title={this.props.article ? t(this.props.article.name) : ""}
                height="80vh"
                width="75vw"
                position={{ my: "top", at: "top", of: window, offset: "0 10" }}
            >
                <ScrollView>
                    <div id="createCookingProcessPopup">
                        <Switch
                            defaultValue={this.state.switchedOn}
                            switchedOnText={t("Portionen")}
                            switchedOffText={t("Gewicht")}
                            width={100}
                            height={40}
                            onOptionChanged={this.onSwitchOptionChanged}
                            style={{ float: "right" }}
                        />
                        {this.props.article && this.state.switchedOn && (
                            <div style={{ clear: "both" }}>
                                <DataGrid
                                    id={"gridContainer"}
                                    dataSource={this.props.article.ArticleSizesWithoutDefaultSize}
                                    columnAutoWidth={true}
                                    ref={this.dataGridArticleSizesWithoutDefaultSize}
                                    rowAlternationEnabled={true}
                                    allowColumnReordering={false}
                                    onRowUpdated={(e) => {
                                        this.setState({ update: !this.state.update });
                                    }}
                                    wordWrapEnabled={true}
                                >
                                    <Sorting mode="none" />
                                    <Editing mode={"cell"} allowUpdating={true} selectTextOnEditStart={true} />
                                    <Column
                                        caption={t("Dishes.Size")}
                                        dataField={"Size.displayName"}
                                        allowEditing={false}
                                        calculateDisplayValue={(e) => {
                                            return t(e.Size.displayName);
                                        }}
                                    />
                                    <Column
                                        caption={t("Dishes.Amount")}
                                        width={200}
                                        dataField={"cookingProcessAmountHelper"}
                                        allowEditing={true}
                                        editorOptions={{
                                            min: 0,
                                        }}
                                    />
                                </DataGrid>
                            </div>
                        )}
                        {this.props.article && !this.state.switchedOn && (
                            <div style={{ clear: "both" }}>
                                <DataGrid
                                    id={"gridContainer"}
                                    dataSource={
                                        this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess
                                    }
                                    columnAutoWidth={true}
                                    ref={this.dataGridParentCookingProcess}
                                    rowAlternationEnabled={true}
                                    allowColumnReordering={false}
                                    onRowUpdated={(e) => {
                                        this.setState({ update: !this.state.update });
                                    }}
                                    wordWrapEnabled={true}
                                >
                                    <Sorting mode="none" />
                                    <Editing mode={"cell"} allowUpdating={true} selectTextOnEditStart={true} />
                                    <Column
                                        caption="Artikel"
                                        dataField={"articleName"}
                                        allowEditing={false}
                                        calculateDisplayValue={(e) => {
                                            return t(e.articleName);
                                        }}
                                    />
                                    <Column
                                        caption={t("Dishes.WeightInG")}
                                        width={200}
                                        dataField={"existingWeight"}
                                        allowEditing={true}
                                        editorOptions={{
                                            min: 0,
                                        }}
                                    />
                                </DataGrid>
                            </div>
                        )}
                        {this.props.article &&
                            this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses.length > 0 && (
                                <AllCookingProcessesDatagrid
                                    childCookingProcesses={
                                        this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses
                                    }
                                    dataGridChildCookingProcesses={this.dataGridChildCookingProcesses}
                                    articleSizesWithoutDefaultSize={this.props.article.ArticleSizesWithoutDefaultSize}
                                    parentCookingProcess={
                                        this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                    }
                                    update={this.state.update}
                                    childCookingProcessExistingWeightUpdated={
                                        this.childCookingProcessExistingWeightUpdated
                                    }
                                    switchedOn={this.state.switchedOn}
                                />
                            )}
                        {this.props.article &&
                            this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                .ingredientsWithoutCookingProcesses.length > 0 && (
                                <IngredientsDatagrid
                                    articleSizesWithoutDefaultSize={this.props.article.ArticleSizesWithoutDefaultSize}
                                    childCookingProcesses={
                                        this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses
                                    }
                                    parentCookingProcess={
                                        this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                    }
                                    update={this.state.update}
                                    switchedOn={this.state.switchedOn}
                                />
                            )}
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                            <Button text={t("Dishes.Save")} type="default" onClick={this.handleSave} />
                            {!this.props.cookingProcess && (
                                <Button
                                    text={t("Dishes.SaveAndStart")}
                                    type="default"
                                    onClick={(e) => this.handleSave(e, true)}
                                />
                            )}
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        );
    }

    delay(milliseconds) {
        return new Promise((resolve) => {
            setTimeout(resolve, milliseconds);
        });
    }

    async handleSave(e, start = false) {
        await this.delay(250);
        const { t } = this.props;
        let hasObjectWithPositiveValue = false;
        if (this.props.article) {
            if (this.state.switchedOn) {
                hasObjectWithPositiveValue = this.props.article.ArticleSizesWithoutDefaultSize.some(
                    (obj) => obj.cookingProcessAmountHelper > 0
                );
            } else {
                if (this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess.length > 0) {
                    hasObjectWithPositiveValue =
                        this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess.some(
                            (obj) => obj.existingWeight > 0
                        );
                }
            }
        }
        if (hasObjectWithPositiveValue === true) {
            if (this.state.switchedOn) {
                this.dataGridArticleSizesWithoutDefaultSize.current
                    .instance()
                    .saveEditData()
                    .then((result) => {
                        if (this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses.length > 0) {
                            this.dataGridChildCookingProcesses.current
                                .instance()
                                .saveEditData()
                                .then((result) => {
                                    let amountForSizes = [];
                                    let existingArticleWeights = [];

                                    this.props.article.ArticleSizesWithoutDefaultSize.map((articleSize) => {
                                        return amountForSizes.push({
                                            articleSizesId: articleSize.articleSizesId,
                                            amount: articleSize.cookingProcessAmountHelper
                                                ? articleSize.cookingProcessAmountHelper
                                                : 0,
                                        });
                                    });

                                    this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses.map(
                                        (articleWeight) => {
                                            return existingArticleWeights.push({
                                                articlesId: articleWeight.articlesId,
                                                weight: articleWeight.existingWeight ? articleWeight.existingWeight : 0,
                                            });
                                        }
                                    );
                                    this.props.handleSaveArticleSizes(amountForSizes, existingArticleWeights, start);
                                    this.props.togglePopup();
                                });
                        } else {
                            let amountForSizes = [];

                            this.props.article.ArticleSizesWithoutDefaultSize.map((articleSize) => {
                                return amountForSizes.push({
                                    articleSizesId: articleSize.articleSizesId,
                                    amount: articleSize.cookingProcessAmountHelper
                                        ? articleSize.cookingProcessAmountHelper
                                        : 0,
                                });
                            });

                            this.props.handleSaveArticleSizes(amountForSizes, null, start);
                            this.props.togglePopup();
                        }
                    });
            } else {
                this.dataGridParentCookingProcess.current
                    .instance()
                    .saveEditData()
                    .then((result) => {
                        if (this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses.length > 0) {
                            this.dataGridChildCookingProcesses.current
                                .instance()
                                .saveEditData()
                                .then((result) => {
                                    let articleWeights = [];
                                    let existingArticleWeights = [];

                                    articleWeights.push({
                                        articlesId:
                                            this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                                .articlesId,
                                        weight: this.props.article.AllCookingProcessesForThisArticle
                                            .parentCookingProcess[0].existingWeight
                                            ? this.props.article.AllCookingProcessesForThisArticle
                                                  .parentCookingProcess[0].existingWeight
                                            : 0,
                                    });

                                    this.props.article.AllCookingProcessesForThisArticle.childCookingProcesses.map(
                                        (articleWeight) => {
                                            return existingArticleWeights.push({
                                                articlesId: articleWeight.articlesId,
                                                weight: articleWeight.existingWeight ? articleWeight.existingWeight : 0,
                                            });
                                        }
                                    );

                                    this.props.handleSaveArticleWeights(articleWeights, existingArticleWeights, start);
                                    this.props.togglePopup();
                                });
                        } else {
                            let articleWeights = [];

                            articleWeights.push({
                                articlesId:
                                    this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                        .articlesId,
                                weight: this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                    .existingWeight
                                    ? this.props.article.AllCookingProcessesForThisArticle.parentCookingProcess[0]
                                          .existingWeight
                                    : 0,
                            });

                            this.props.handleSaveArticleWeights(articleWeights, null, start);
                            this.props.togglePopup();
                        }
                    });
            }
            notify(getToast(t("Speichern erfolgreich"), "success"));
        } else {
            notify(getToast(t("Dishes.NoAmountOrWeightSelected"), "error"));
        }
    }
}

export default withTranslation(["dynamicTranslation"])(CreateCookingProcessPopup);
