import {
    RESET_OFFER_STATE,
    SET_DRAWER_OPEN,
    SET_IS_LOADING,
    SET_IS_LOADING_PREVIEWS,
    SET_OFFER_TYPES,
    SET_OFFERS,
    SET_ORDER_TYPES,
    SET_SELECTED_OFFER,
    SET_SELECTED_POST,
    SET_SELECTED_PRODUCT,
    SET_SELECTED_TEMPLATE,
    SET_SHOW_DELETE_CONFIRMATION,
    SET_SHOW_OFFER_EDITOR,
    SET_TEMPLATE_PREVIEWS,
    SET_VALIDATION_ERRORS,
    UPDATE_TIME_PERIOD_DATA,
    UPDATE_BASIC_DATA,
    UPDATE_CAMPAIGN_PERIOD,
    SET_SELECTED_CATEGORY,
    SET_SELECTED_SIZE,
    SET_SELECTED_DAYS,
    SET_SELECTED_PRICE,
    SET_PRODUCT_TO_EDIT,
    TOGGLE_FORMAT_SELECTION,
} from "../actions/offerActions";

const initialState = {
    isDrawerOpen: true,
    offers: [],
    isLoading: false,
    hasFetchStarted: false,
    selectedOffer: null,
    isOfferEditorOpen: false,
    isDeleteConfirmationOpen: false,
    errors: {},
    timePeriodData: {
        hasValidityPeriod: false,
        hasRepetition: false,
    },
    selectedTemplate: null,
    templatePreviews: null,
    isLoadingPreviews: false,
    basicData: {
        offerType: null,
        title: null,
        orderTypesId: null,
        orderType: null,
        isActive: false,
        StoreTimes: {
            start: null,
            end: null,
            times: null,
        },
        repeatInterval: null,
        repeatUnit: null,
    },
    products: {
        product: null,
        productToEdit: null,
        selectedCategory: "all",
        selectedSize: null,
        selectedDays: [],
        selectedPrice: null,
    },
    media: {
        selectedFormats: [],
    },
    schedule: {
        campaignPeriod: {
            start: null,
            end: null,
        },
        selectedPost: null,
    },
    offerTypes: [],
    orderTypes: [],
};

export default function offerReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_OFFER_STATE:
            return initialState;
        case SET_DRAWER_OPEN:
            return { ...state, isDrawerOpen: action.payload };
        case SET_OFFERS:
            return { ...state, offers: action.payload };
        case SET_IS_LOADING:
            return { ...state, isLoading: action.payload, hasFetchStarted: true };
        case SET_SELECTED_OFFER:
            const offer = action.payload;
            const currentTimePeriodData = state.timePeriodData || {};
            const isInitialLoad = !state.selectedOffer;

            return {
                ...state,
                selectedOffer: offer,
                errors: {},
                timePeriodData: isInitialLoad
                    ? {
                          hasValidityPeriod: !!(offer?.StoreTimes?.start || offer?.StoreTimes?.times),
                          hasRepetition: !!(offer?.repeatInterval > 0),
                      }
                    : currentTimePeriodData,
                basicData: {
                    offerType: offer?.offerType || null,
                    title: offer?.title || null,
                    orderTypesId: offer?.orderTypesId || null,
                    orderType: offer?.orderType || null,
                    isActive: offer?.isActive || false,
                    StoreTimes: {
                        start: offer?.StoreTimes?.start || null,
                        end: offer?.StoreTimes?.end || null,
                        times: offer?.StoreTimes?.times || null,
                    },
                    repeatInterval: offer?.repeatInterval || null,
                    repeatUnit: offer?.repeatUnit || null,
                },
            };
        case SET_SHOW_OFFER_EDITOR:
            return { ...state, isOfferEditorOpen: action.payload };
        case SET_SHOW_DELETE_CONFIRMATION:
            return { ...state, isDeleteConfirmationOpen: action.payload };
        case SET_VALIDATION_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case SET_OFFER_TYPES:
            return { ...state, offerTypes: action.payload };
        case SET_ORDER_TYPES:
            return { ...state, orderTypes: action.payload };
        case UPDATE_TIME_PERIOD_DATA:
            return {
                ...state,
                timePeriodData: {
                    ...state.timePeriodData,
                    ...action.payload,
                },
            };
        case SET_SELECTED_TEMPLATE:
            return { ...state, selectedTemplate: action.payload };
        case SET_TEMPLATE_PREVIEWS:
            return { ...state, templatePreviews: action.payload };
        case SET_IS_LOADING_PREVIEWS:
            return { ...state, isLoadingPreviews: action.payload };
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: action.payload,
                },
            };
        case SET_SELECTED_CATEGORY:
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedCategory: action.payload,
                },
            };
        case SET_SELECTED_SIZE:
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedSize: action.payload,
                },
            };
        case SET_SELECTED_DAYS:
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedDays: action.payload,
                },
            };
        case SET_SELECTED_PRICE:
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedPrice: action.payload,
                },
            };
        case SET_PRODUCT_TO_EDIT:
            return {
                ...state,
                products: {
                    ...state.products,
                    productToEdit: action.payload,
                },
            };
        case TOGGLE_FORMAT_SELECTION:
            const currentFormats = state.media?.selectedFormats || [];
            const format = action.payload;
            const newSelectedFormats = currentFormats.some((f) => f.id === format.id)
                ? currentFormats.filter((f) => f.id !== format.id)
                : [...currentFormats, format];

            return {
                ...state,
                media: {
                    ...state.media,
                    selectedFormats: newSelectedFormats,
                },
            };
        case UPDATE_CAMPAIGN_PERIOD:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    campaignPeriod: {
                        ...state.schedule.campaignPeriod,
                        ...action.payload,
                    },
                },
            };
        case SET_SELECTED_POST:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    selectedPost: action.payload,
                },
            };
        case UPDATE_BASIC_DATA:
            return {
                ...state,
                basicData: {
                    ...state.basicData,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
