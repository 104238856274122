import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getCashPoints(page = 1, pageSize = 20, date) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getCashPoints?page=${page}&pageSize=${pageSize}&date=${date ?? new Date()}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}
export function addCashPointEntry(documentDate, text, category, cash_receipts, cash_paidout) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ documentDate, text, category, cash_receipts, cash_paidout }),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/addCashPointEntry`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllBusinessTransactionTypes() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllBusinessTransactionTypes`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function cancelCashPointEntry(cashpointId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ cashpointId }),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/cancelCashPointEntry`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function printCashJournal(cashpoints) {
    return function (dispatch) {
        const cashpointIds = cashpoints.map(cp => cp.cashpointId);
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ cashpointIds }),
        };

        return fetch(config.backendHost + "/Reports/CreateCashJournalReport", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateBusinessTransactionType(businessTransactionTypeId, description, isActive) {
    return function (dispatch) {
        const requestOptions = {
            method: "PUT",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ businessTransactionTypeId, description, isActive }),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/updateBusinessTransactionType`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getStock() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getStock`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}