import { combineReducers } from "redux";
import menuCardReducer from "./menuCardReducer";
import storeTimesReducer from "./storeTimesReducer";
import navigation from "./navigationReducer";
import { users } from "./usersReducer";
import identity from "./identityReducer";
import dragAndDrop from "./dragAndDropReducer";
import recipe from "./recipeReducer";
import cookingProcess from "./cookingProcessReducer";
import license from "./licenseReducer";
import theme from "./themeReducer";
import mediaFolder from "./mediaFolderReducer";
import orderConnection from "./orderConnectionReducer";
import settings from "./settingsReducer";
import pages from "./pagesReducer";
import articleDetailsPage from "./articleDetailsPageReducer";
import orderManagement from "./orderManagementReducer";
import paymentTypesReducer from "./paymentTypesReducer";
import mediaLibrary from "./mediaLibraryReducer";
import offer from "./offerReducer";
const appReducer = combineReducers({
    menuCardReducer,
    storeTimesReducer,
    users,
    identity,
    dragAndDrop,
    recipe,
    navigation,
    cookingProcess,
    license,
    mediaFolder,
    theme,
    orderConnection,
    settings,
    pages,
    articleDetailsPage,
    orderManagement,
    paymentTypes: paymentTypesReducer,
    mediaLibrary,
    offer,
});

const rootReducer = (state, action) => {
    if (action.type === "APP_RESET") {
        state = undefined;
        window.location.reload(); // TODO: rerender App
    }

    return appReducer(state, action);
};

export default rootReducer;
