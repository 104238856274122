import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import DataGrid, {
    Column,
    Paging,
    StateStoring,
    Editing,
    Texts,
    Scrolling
} from "devextreme-react/data-grid";
import { getAllBusinessTransactionTypes, updateBusinessTransactionType } from '../../../../../../actions/cashPointActions';
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const BusinessTransactionTypes = ({ t }) => {
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        dispatch(getAllBusinessTransactionTypes())
            .then((data) => {
                setDataSource(data);
            })
            .catch((error) => {
                console.error('Error loading business transaction types:', error);
            });
    };

    const handleUpdate = (e) => {
        e.component.beginCustomLoading();
        dispatch(updateBusinessTransactionType(
            e.data.businessTransactionTypeId,
            e.data.description,
            e.data.isActive
        )).then(() => {
            loadData();
            e.component.endCustomLoading();
        });
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                rowAlternationEnabled={true}
                onRowUpdated={handleUpdate}
                height="100%"
                width="100%"
                columnResizingMode="widget"
                onToolbarPreparing={(e) => {
                    let toolbarItems = e.toolbarOptions.items;
                    toolbarItems.forEach(function (item) {
                        if (item.name === "addRowButton") {
                            item.showText = "always";
                            item.options.text = t("Neuer Eintrag");
                        }
                    });
                }}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={20} />
                <StateStoring
                    enabled={true}
                    type={"localStorage"}
                    storageKey={"businessTransactionTypes_storage"}
                />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={false}
                    useIcons={true}
                >
                    <Texts
                        confirmDeleteMessage={t("Möchten Sie diesen Datensatz wirklich löschen?")}
                        yes={t("Ja")}
                        no={t("Nein")}
                    />
                </Editing>
                <Column
                    dataField="name"
                    caption="Name"
                    minWidth={200}
                    allowEditing={false}
                    validationRules={[{ type: "required" }]}
                />
                <Column
                    dataField="description"
                    caption="Beschreibung"
                    minWidth={300}
                />
                <Column
                    dataField="isActive"
                    caption="Aktiv"
                    width={130}
                    dataType="boolean"
                />
            </DataGrid>
        </div>
    );
};

export default compose(
    withTranslation(["dynamicTranslation"])
)(BusinessTransactionTypes);
